import store from '../store/index';
import {baseApiCall} from "./base";

export default {
  getDomains() {
    if (store.state.domains.loaded) { return store.state.domains.userDomains }

    return baseApiCall({
      method: 'GET', url: '/domains/all',
      onSuccess: res => {
        store.commit('domains/setDomains', typeof res === 'object' && res.domains ? res.domains : []);
      }
    });
  },
  async deleteDomain(domainId) {
    return baseApiCall({
      method: 'DELETE', url: '/domains/domain', data: { domainId },
      onSuccess: () => {
        store.commit('domains/removeDomain', domainId);
      }
    });
  },
  async addDomain(data) {
    return baseApiCall({
      method: 'POST', url: '/domains/domain', data,
      onSuccess: res => {
        store.commit('domains/addDomain', res.domain);
      }
    });
  },
  async verifyDomain(domainId) {
    return baseApiCall({
      method: 'POST', url: '/domains/domain/verify', data: { domainId },
      onSuccess: res => {
        store.commit('domains/setDomainVerified', {
          domainId, isVerified: typeof res === 'object' && res.isVerified ? res.isVerified : false
        });
      }
    });
  },
}