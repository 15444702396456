import store from './index';

export default {
  namespaced: true,

  state: () => ({
    show: false,
    url: ''
  }),

  mutations: {
    toggleQrModal(state, show = false) {
      state.show = show;

      if (!show) {
        state.url = '';
      }
    },
    setQrUrl(state, url = '') {
      state.url = url;

      if (url) {
        state.show = true;
      }
    },
  },

  actions: {
  },

  getters: {

  }
}