<template>
  <v-navigation-drawer
      v-model="showMobileMenu"
      temporary
      fixed
      height="100vh"
      class="navigation-drawer--justify"
  >
    <v-list
        nav
        dense
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <router-link to="/" tag="a" class="text-decoration-none cyan--text accent-4" >
              <v-img
                  max-width="60px"
                  src="../assets/images/logo.svg"
              ></v-img>
            </router-link>
          </v-list-item-title>
          <v-list-item-subtitle v-if="false">
            Mobile menu
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-4"></v-divider>

      <v-list-item-group
          active-class="cyan--text text--accent-4"
      >
        <v-list-item
            v-for="menuItem in menuWithoutLogout"
            :key="menuItem.id"
            :to="menuItem.path"
        >
          <v-list-item-icon>
            <v-icon>{{ menuItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-spacer></v-spacer>

    <v-list
        nav
        dense
        v-if="user"
    >
      <v-list-item @click="logout" >
        <v-list-item-icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
  import authApi from '../api/auth';

  export default {
    name: 'MobileMenu',

    computed: {
      ...mapState(['user']),
      ...mapGetters({
        menu: 'getMenu'
      }),
      menuWithoutLogout() {
        return this.menu.filter(menuItem => menuItem.id !== 'logout');
      },
      showMobileMenu: {
        get: function() {
          return this.$store.state.showMobileMenu;
        },
        set: function(newValue) {
          this.$store.commit('setMobileMenuVisibility', newValue);
        }
      }
    },

    beforeMount() {
      this.logout = authApi.logout;
    },

    mounted() {

    },

    methods: {
    },

    data: () => ({
    }),
  }
</script>

<style lang="scss">
.navigation-drawer--justify.v-navigation-drawer {
  .v-navigation-drawer__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
</style>
