import store from '../store/index';
import router from '../router/index';
import Vue from 'vue';
import {baseApiCall} from "./base";

export default {
  changePassword(data) {
    return baseApiCall({
      method: 'POST', url: '/change-password', data, vueInstance: this
    });
  },
  recover(data) {
    return baseApiCall({
      method: 'POST', url: '/recover-password', data, vueInstance: this
    });
  },
  resetPassword(data) {
    return baseApiCall({
      method: 'POST', url: '/reset-password', data, vueInstance: this
    });
  },
  signin(data) {
    return baseApiCall({
      method: 'POST', url: '/signin', data,
      onSuccess: user => store.commit('setUser', user),
      vueInstance: this
    });
  },
  signup(data) {
    return baseApiCall({
      method: 'POST', url: '/signup', data,
      vueInstance: this
    });
  },
  getUser() {
    return baseApiCall({
      method: 'GET', url: '/user', showNotifications: false,
      onSuccess: user => store.commit('setUser', user)
    });
  },
  logout() {
    return baseApiCall({
      method: 'GET', url: '/logout',
      onSuccess: () => {
        Vue.$cookies.remove('connect.sid');
        store.commit('logout');
        router.push('/signin');
      }
    });
  },
}