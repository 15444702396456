import axios from '../api/axios';
import store from '../store/index';
import moment from 'moment';
import { getDataFromError } from "../js/helpers";
import {baseApiCall} from "./base";

export default {
  getAllCampaigns() {
    if (store.state.rotator.rotatorLoaded) { return store.state.rotator.getCampaigns }

    return baseApiCall({
      method: 'GET', url: '/rotator/campaigns',
      onSuccess: (campaigns) => {
        store.commit('rotator/setRotatorData', campaigns);
      }
    });
  },
  async getCampaignsStatistics() {
    if (store.state.rotator.rotatorLoaded) { return store.state.rotator.campaignsStatistics }

    return baseApiCall({
      method: 'GET', url: '/rotator/campaigns/statistics',
      onSuccess: (res) => {
        const {groups = []} = res;
        const campaignsStatistics = {};

        groups.forEach(group => {
          const {_id, campaigns = {}} = group;
          if (!_id || campaignsStatistics.hasOwnProperty(_id)) { return }

          campaignsStatistics[_id] = campaigns;
        });

        store.commit('rotator/setCampaignsStatistics', campaignsStatistics);
      }
    });
  },
  async getCampaignData(campaignId) {
    return baseApiCall({
      method: 'GET', url: `/rotator/campaign?id=${campaignId}`
    });
  },
  async addPixel(data) {
    return baseApiCall({
      method: 'POST', url: `/rotator/pixel`, data, vueInstance: this,
      onSuccess: (res) => store.commit('rotator/addPixel', res.pixel)
    });
  },
  async deletePixel(pixelId) {
    return baseApiCall({
      method: 'DELETE', url: `/rotator/pixel`, data: { pixelId },
      onSuccess: () => store.commit('rotator/removePixel', pixelId)
    });
  },
  async deleteCampaign(campaignId) {
    return baseApiCall({
      method: 'DELETE', url: `/rotator/campaign/delete`, data: { campaignId },
      onSuccess: () => store.commit('rotator/removeCampaign', campaignId)
    });
  },
  async updateCampaign(newCampaign) {
    return baseApiCall({
      method: 'PUT', url: `/rotator/campaign/update`, data: { ...newCampaign }, showNotifications: false,
      onSuccess: () =>  store.commit('rotator/updateCampaign', newCampaign)
    });
  },
  async addCampaign(newCampaign) {
    return baseApiCall({
      method: 'POST', url: `/rotator/campaign/create`, data: { ...newCampaign }, vueInstance: this,
      onSuccess: (res) => {
        const {campaignId, key} = res;

        newCampaign.key = key;
        newCampaign._id = campaignId;
        newCampaign.createdAt = moment().format();

        store.commit('rotator/addCampaign', newCampaign);
      }
    });
  },
  async loadRedirectionsByPage(data) {
    if (!data.results) { data.results = 8 }
    if (!data.page) { data.page = 1 }

    return baseApiCall({
      method: 'POST', url: `/rotator/campaign/redirections`, data
    });
  },
  async loadStatisticsChartByPeriod(data) {
    return baseApiCall({
      method: 'GET', url: `/rotator/campaign/chart?campaignId=${data.campaignId}&chartType=${data.chartType}`
    });
  },
  async resetStatistics(campaignId) {
    return baseApiCall({
      method: 'DELETE', url: `/rotator/campaign/redirections`, data: { campaignId },
      onSuccess: () => store.commit('rotator/resetStatistics', campaignId)
    });
  },
  getRedirection: async (key) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios({
          method: 'GET',
          url: `/rotator/${key}`
        });

        if (res.status === 200 && res.data) {
          window.location.href = 'https://www.amazon.com';
        }

        resolve(res.data);
      } catch (err) {
        reject(getDataFromError(err));
      }
    });
  },
}