import { mapDomain } from '../js/domains';
import config from '../../config';
import store from './index';

export default {
  namespaced: true,

  state: () => ({
    allDomains: [],
    loaded: false
  }),

  mutations: {
    setDomains(state, domains) {
      state.allDomains = domains.map(mapDomain);
      state.loaded = true;
    },
    removeDomain(state, domainId) {
      state.allDomains = state.allDomains.filter(_domain => _domain._id !== domainId);
      store.commit('rotator/domainDeleted', domainId);
    },
    addDomain(state, newDomain) {
      state.allDomains.push(mapDomain(newDomain));
    },
    setDomainVerified(state, { domainId, isVerified } ) {
      const updatedDomain = state.allDomains.find(_domain => _domain._id === domainId);
      if (!updatedDomain) { return }

      updatedDomain.isVerified = isVerified;
    }
  },

  actions: {
  },

  getters: {
    domainsWithoutDefaultSystem(state) {
      return state.allDomains.filter(_domain => !_domain.isDefaultSystemDomain);
    },
    defaultSystemDomain(state) {
      return state.allDomains.find(_domain => _domain.isDefaultSystemDomain);
    },
    verifiedDomains(state) {
      return state.allDomains.filter(_domain => _domain.isVerified && !_domain.isDefaultSystemDomain);
    },
    verifiedDomainsAndDefault(state, getters) {
      const defaultSystemDomain = getters.defaultSystemDomain;

      const domains = [ ...getters.verifiedDomains ];
      if (defaultSystemDomain) {
        domains.push(defaultSystemDomain);
      }

      return domains;
    },
    defaultDomain(state, getters) {
      const verifiedDomains = getters.verifiedDomains;
      const defaultSystemDomain = getters.defaultSystemDomain || { value: '' };

      return verifiedDomains.length ? verifiedDomains[0].value : defaultSystemDomain.value;
    },
    userDomains(state, getters, rootState) {
      const { campaigns = [] } = rootState.rotator;
      const verifiedDomains = getters.verifiedDomains;
      const firstVerifiedDomain = verifiedDomains.length ? verifiedDomains[0].value : null;
      const domainsWithoutDefaultSystem = getters.domainsWithoutDefaultSystem;

      const countDomainsByCampaigns = campaigns.reduce((domainsObj, campaign) => {
        let domainId = campaign.customDomain || firstVerifiedDomain;

        if (!domainId) { return domainsObj }

        if (!domainsObj.hasOwnProperty(domainId)) {
          domainsObj[domainId] = 0;
        }

        domainsObj[domainId] += 1;

        return domainsObj;
      }, {});


      return domainsWithoutDefaultSystem.map(_domain => {
        const domainId = _domain._id;
        const campaignsCount = countDomainsByCampaigns[domainId] || 0;

        return { ..._domain, campaignsCount }
      });
    },
    domainsDataById(state) {
      return state.allDomains.reduce((_domainsDataById, _domain) => {
        const { _id } = _domain

        _domainsDataById[_id] = _domain;

        return _domainsDataById;
      }, {});
    }
  }
}