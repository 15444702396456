<template>
  <v-app class="my-app">

    <template v-if="!isRedirectPage">

      <Loader :show="loading" :size="64" :absolute="false" />

      <MobileMenu />

    </template>


    <v-tooltip :value="false"></v-tooltip>

    <div :class="{'opacity-0': loading && !initialLoaded}">
      <v-app-bar
          app
          color="white"
          v-if="!isRedirectPage"
      >
        <Header />
      </v-app-bar>

      <notifications position="bottom center" group="global" width="500">
        <template slot="body" slot-scope="{ item, close }">
          <v-alert
              dense
              dark
              class="pa-4"
              :color="mapNotificationTypeAndStyles[item.type] ? mapNotificationTypeAndStyles[item.type].color : 'grey lighten-2'"
          >
            <div class="d-flex align-icon-center">
              <v-icon small >
                {{ mapNotificationTypeAndStyles[item.type] ? mapNotificationTypeAndStyles[item.type].icon : 'mdi-information' }}
              </v-icon>
              <v-divider
                  vertical
                  class="mx-5 grey lighten-2"
                  light
              ></v-divider>
              <div>
                <div class="text-subtitle-2" v-if="item.title">{{ item.title }}</div>
                <div class="text-body-2" v-if="item.text" v-htlm="item.text"></div>
              </div>
              <v-spacer></v-spacer>
              <v-icon small @click="close">mdi-close</v-icon>
            </div>
          </v-alert>
        </template>
      </notifications>

      <v-main :class="{'opacity-0': loading, 'mt-6': !isRedirectPage}" color="grey lighten-2" app>
        <router-view/>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Loader from './components/Loader';
import Header from './components/Header';
import MobileMenu from './components/MobileMenu';
import { mapState } from 'vuex';
import {showNotification} from "./js/helpers";

export default {
  name: 'App',

  components: {
    Header,
    Loader,
    MobileMenu
  },

  async mounted() {
  },

  updated() {
    this.$nextTick(function() {
      document.querySelectorAll('.v-data-table-header__icon').forEach(icon => {
        icon.classList.remove('mdi-arrow-up');
        icon.classList.add('mdi-chevron-up');
      })
    });
  },

  watch: {
  },

  methods: {
  },

  computed: {
    ...mapState(['initialLoaded', 'loading']),
    isRedirectPage() {
      return this.$route.path.indexOf('redirect') !== -1;
    }
  },

  data: () => ({
    mapNotificationTypeAndStyles: {
      'success': {
        icon: 'mdi-checkbox-marked-circle',
        color: 'green lighten-2'
      },
      'error': {
        icon: 'mdi-alert',
        color: 'red lighten-2'
      }
    }
  }),
};
</script>

<style lang="scss">
@import './scss/main';
</style>