import { mapPixels, mapCampaign, rotatorTypes } from '../js/rotator';
import store from './index';
import { perfotekEmail } from '../helpers/contants';

export default {
  namespaced: true,

  state: () => ({
    rotatorTypes,

    pixels: [],
    keywordDataByCampaign: {},
    campaigns: [],
    campaignsStatistics: {},
    rotatorLoaded: false
  }),

  mutations: {
    setRotatorData(state, data) {
      const { campaigns = [], keywordDataByCampaign = {}, pixels = [] } = data;

      state.campaigns = campaigns;
      state.keywordDataByCampaign = keywordDataByCampaign;
      state.pixels = pixels;

      state.rotatorLoaded = true;
    },
    setCampaignsStatistics(state, campaignsStatistics) {
      state.campaignsStatistics = campaignsStatistics;
    },
    addPixel(state, pixel) {
      state.pixels.push(pixel);
    },
    removePixel(state, pixelId) {
      state.pixels = state.pixels.filter(_pixel => _pixel._id !== pixelId);
    },
    removeCampaign(state, campaignId) {
      state.campaigns = state.campaigns.filter(_campaign => _campaign._id !== campaignId);
    },
    updateCampaign(state, newCampaign) {
      const campaignToUpdate = state.campaigns.find(_campaign => _campaign._id === newCampaign.campaignId);
      if (!campaignToUpdate) { return }

      campaignToUpdate.name = newCampaign.name;
      campaignToUpdate.asin = newCampaign.asin;
      campaignToUpdate.market = newCampaign.market;
      campaignToUpdate.customDomain = newCampaign.customDomain;
    },
    addCampaign(state, newCampaign) {
      state.campaigns.unshift(newCampaign);
    },
    resetStatistics(state, campaignId) {
      const { campaignsStatistics } = state;

      Object.keys(campaignsStatistics).forEach(day => {
        const campaignsData = campaignsStatistics[day];

        Object.keys(campaignsData).forEach(_campaignId => {
          if (_campaignId === campaignId) {
            campaignsStatistics[day][_campaignId] = 0;
          }
        });
      });
    },
    domainDeleted(state, domainId) {
      const { campaigns } = state;
      const defaultSystemDomain = store.getters['domains/defaultSystemDomain'];

      campaigns.forEach(campaign => {
        if (campaign.customDomain === domainId && defaultSystemDomain && defaultSystemDomain._id) {
          campaign.customDomain = defaultSystemDomain._id;
        }
      });
    }
  },

  actions: {
  },

  getters: {
    getAvailableRotatorTypes(state, getters, rootState, rootGetters) {
      const rotatorTypesCopy = JSON.parse(JSON.stringify(state.rotatorTypes));
      return rotatorTypesCopy;
    },
    getCampaigns(state, getters, rootState, rootGetters) {
      let { campaigns } = state;
      return campaigns.map(_campaign => mapCampaign(_campaign, state.keywordDataByCampaign, state.campaignsStatistics));
    },
    getPixels(state) {
      return state.pixels.map(mapPixels);
    },
    getMarketplacesOptions(state, getters, rootState) {
      const { dataByMarketplaceId = {} } = rootState;
      return Object.values(dataByMarketplaceId).reduce((options, dataByMarketplace) => {
        const { marketplaceId, url, abbr } = dataByMarketplace;

        options.push({ value: marketplaceId, text: url, icon: abbr });

        return options;
      }, []);
    }
  }
}