import axios from './axios';
import {getDataFromError, showNotification} from "../js/helpers";
import { setErrors } from '../js/helpers.js';

const onBadResponseHandler = (err, vueInstance, showNotifications = false) => {
  const { status, errors = {}, message } = err;
  if (status) { return }

  if (showNotifications) {
    showNotification({ type: 'error', title: message });
  }

  if (vueInstance) {
    setErrors(errors, vueInstance);
  }
};

export const baseApiCall = configuration => {
  const {
    method = 'GET', url, onSuccess, data, vueInstance, showNotifications = true
  } = configuration;

  return new Promise(async resolve => {
    const options = { method, url };
    if (data) { options.data = data }

    try {
      const res = await axios(options);

      if (res.status === 200 && onSuccess) {
        onSuccess(res.data);
      }

      if (res.status !== 200 && typeof res.data === 'object') {
        onBadResponseHandler(res.data, vueInstance, showNotifications);
      }

      resolve(res.data);
    } catch (err) {
      const error = getDataFromError(err);

      onBadResponseHandler(error, vueInstance, showNotifications);
      resolve(error);
    }
  });
};