import { DATE_FORMATS } from '../helpers/contants';
import moment from 'moment';

export const mapUser = (user) => {
  const { accessGranted = false, accessGrantedUntil, createdAt } = user;

  let dateFormatted, accessGrantedUntilFormatted, dateToSort, accessGrantedUntilToSort = 0;

  if (accessGrantedUntil) {
    accessGrantedUntilFormatted = moment(accessGrantedUntil).format(DATE_FORMATS.fullDateWithTime);
    accessGrantedUntilToSort = moment(accessGrantedUntil).format(DATE_FORMATS.unixMillisecondTimestamp);
  }

  if (createdAt) {
    dateFormatted = moment(createdAt).format(DATE_FORMATS.fullDateWithTime);
    dateToSort = moment(createdAt).format(DATE_FORMATS.unixMillisecondTimestamp);
  }

  return { ...user, dateFormatted, dateToSort, accessGranted, accessGrantedUntil, accessGrantedUntilFormatted, accessGrantedUntilToSort }
}