import config from '../../config';
import axios from 'axios';
import { showNotification } from '../js/helpers';
import router from '../router/index';
import authApi from './auth';
import { validationMessages } from '../helpers/contants';

const instance = axios.create({
  withCredentials: true,
  baseURL: config.apiUrl,
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  }
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  const data = err?.response?.data || {};

  if (data && data.message === 'access') {
    showNotification({ type: 'error', title: validationMessages[data.message] });
    authApi.logout();
    return;
  }

  return Promise.resolve(err?.response);
});

export default instance;
