import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';

import Notifications from 'vue-notification';
import VueCookies from 'vue-cookies';
import VueApexCharts from 'vue-apexcharts';

Vue.config.productionTip = false

Vue.use(Notifications);
Vue.use(VueCookies);
Vue.use(VueApexCharts);

Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);

Vue.component('apexchart', VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
