<template>
  <v-container fluid class="px-8">
    <v-row >
      <v-toolbar class="toolbar--no-paddings-x" elevation="0" dense flat>
        <v-toolbar-title>
          <router-link to="/" tag="a" class="text-decoration-none cyan--text accent-4" >
            <v-img
                max-width="90px"
                src="../assets/images/logo.svg"
            ></v-img>
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class=" mr-n4 d-none d-md-flex">

          <v-btn
              v-for="menuItem in menu"
              :key="menuItem.id"
              :to="menuItem.path"
              plain
              small
              @click="handleMenuItemClicked(menuItem.id)"
          >
            <v-icon dense small class="mr-1">{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>
        </v-toolbar-items>

        <v-app-bar-nav-icon class="d-inline-block d-md-none" @click="setMobileMenuVisibility(true)"></v-app-bar-nav-icon>
      </v-toolbar>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
  import authApi from '../api/auth';

  export default {
    name: 'Header',

    computed: {
      ...mapGetters({
        menu: 'getMenu'
      })
    },

    beforeMount() {
      this.logout = authApi.logout;
    },

    methods: {
      ...mapMutations(['setMobileMenuVisibility']),
      handleMenuItemClicked(id) {
        if (id === 'logout') {
          this.logout();
        }
      }
    },

    data: () => ({

    }),
  }
</script>

<style lang="scss">

</style>
