import { mapUser } from '../js/users';
import config from '../../config';
import moment from "moment";
import {DATE_FORMATS} from "../helpers/contants";

export default {
  namespaced: true,

  state: () => ({
    userList: [],
    loaded: false
  }),

  mutations: {
    setUsers(state, users) {
      state.userList = users.map(mapUser);
      state.loaded = true;
    },
    updateUser(state, data) {
      const { _id, accessGrantedUntil, accessGranted } = data;

      const updatedUser = state.userList.find(_user => _user._id === _id);
      if (!updatedUser) { return }

      updatedUser.accessGrantedUntil = accessGrantedUntil;
      updatedUser.accessGranted = accessGranted;

      if (accessGranted && updatedUser.accessGrantedUntil) {
        updatedUser.accessGrantedUntilFormatted = moment(accessGrantedUntil).format(DATE_FORMATS.fullDateWithTime);
      } else {
        updatedUser['accessGrantedUntilFormatted'] = '';
      }

    },
  },

  actions: {
  },

  getters: {

  }
}