import moment from 'moment';

export const DATE_FORMATS = {
  yearMonthDay: 'YYYYMMDD',
  yearMonthDayWithDash: 'YYYY-MM-DD',
  fullDateWithTime: 'D MMM YYYY, hh[:]mm A',
  yearMonth: 'YYYY MMM',
  yearMonthDaySpaces: 'YYYY MMM DD',
  unixMillisecondTimestamp: 'x'
};

export const LA_TIMEZONE = 'America/Los_Angeles';

export const TODAY = moment();
export const TODAY_FORMATTED_YEAR_MONTH_DAY = moment().format(DATE_FORMATS.yearMonthDay);
export const TWO_WEEK_AGO = moment().subtract(13, 'days');
export const TWO_WEEK_AGO_FORMATTED_YEAR_MONTH_DAY = TWO_WEEK_AGO.format(DATE_FORMATS.yearMonthDay);

export const perfotekEmail = 'info@perfotek.us';

export const validationMessages = {
  'access': 'You don\'t have access to this tool!',
  'oldPassword': 'Wrong password',
  'api': 'Something going wrong',
  'server': 'Something going wrong',
};
