<template>
  <v-overlay class="light--overlay" :absolute="absolute" :value="show" :z-index="9999" :opacity="0.75">
    <v-progress-circular
        indeterminate
        :size="size"
        color="cyan accent-4"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>

export default {
  name: 'Loader',

  computed: {

  },

  props: {
    show: { type: Boolean, default: false },
    size: { type: Number, default: 64 },
    absolute: { type: Boolean, default: false }
  },

  watch: {

  },

  created() {

  },

  mounted() {

  },

  methods: {

  },

  data: () => ({

  }),
}
</script>

<style lang="scss">
.light--overlay .v-overlay__scrim {
  background: #fff !important;
  border-color: #fff !important;
}
</style>
