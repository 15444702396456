import {DATE_FORMATS, TODAY_FORMATTED_YEAR_MONTH_DAY, TWO_WEEK_AGO_FORMATTED_YEAR_MONTH_DAY} from '../helpers/contants';
import moment from 'moment';

const power = 1.63; // power gives us f(1) -> 1, f(2) -> 3, f(3) -> 6
export const getKeywordDistributionByPriority = keywordPriorities => {
  const totalPriorities = Object.values(keywordPriorities).map(priority => parseInt(priority)).reduce((acc, cur) => acc + Math.round(Math.pow(cur, power)), 0);
  const keywordDistribution = {};
  for (let keyword of Object.keys(keywordPriorities)) {
    const keywordPriority = Math.round(Math.pow(parseInt(keywordPriorities[keyword]), power));
    keywordDistribution[keyword] = parseFloat((keywordPriority/totalPriorities).toFixed(3));
  }
  return keywordDistribution;
};

export const mapCampaign = (campaign, keywordDataByCampaign, campaignsStatistics) => {
  let totalCampaignDays = 0, totalCampaignWeeks = 0, totalCampaignMonth = 0;
  const campaignStatisticsExist = keywordDataByCampaign[campaign._id];
  if (campaignStatisticsExist) {
    Object.keys(campaignStatisticsExist).forEach(key => {
      if (campaignStatisticsExist[key].day) { totalCampaignDays += campaignStatisticsExist[key].day }
      if (campaignStatisticsExist[key].week) { totalCampaignWeeks += campaignStatisticsExist[key].week }
      if (campaignStatisticsExist[key].month) { totalCampaignMonth += campaignStatisticsExist[key].month }
    });
  }

  const totalCampaignStatistics = {
    day: totalCampaignDays,
    week: totalCampaignWeeks,
    month: totalCampaignMonth
  };

  campaign.chartData = [];

  let startDate = parseInt(TWO_WEEK_AGO_FORMATTED_YEAR_MONTH_DAY, 10);
  let endDate = parseInt(TODAY_FORMATTED_YEAR_MONTH_DAY, 10);

  while (startDate <= endDate) {
    const dayData = campaignsStatistics[startDate] || {};

    const {_id: campaignId} = campaign;
    if (!campaignId) { return }

    const dataByDay = dayData[campaignId] || 0;
    campaign.chartData.push(dataByDay);

    if (startDate === endDate) {
      totalCampaignStatistics.twoWeek = campaign.chartData.reduce((total, dayData) => total += dayData, 0);
    }

    startDate = parseInt(moment(startDate, DATE_FORMATS.yearMonthDay).add(1, 'day').format(DATE_FORMATS.yearMonthDay), 10);
  }

  return {
    ...campaign,
    data: campaign['data'] || {},
    url: `https://shopnsave.club/${campaign.key}`,
    keywordPriorities: campaign.keywordPriorities ? campaign.keywordPriorities : {},
    statistics: totalCampaignStatistics,
    grUrlRotation: campaign.grUrlRotation ? campaign.grUrlRotation : false,
    ppcRedirect: campaign.ppcRedirect ? campaign.ppcRedirect : false,
    pin: campaign.pin ? true : false,
    rotationType: campaign.rotationType || 'random',
    pinLoading: false,
    grUrlGenerationType: campaign.grUrlGenerationType || 'redirectGenerator',
    multipleKeywords: campaign.multipleKeywords || [],
    forceAmazonApp: campaign.forceAmazonApp || false
  }
}

export const mapPixels = (pixel) => {
  return {
    id: pixel._id,
    text: pixel.name,
    value: pixel._id,
    trackId: pixel.trackId,
    type: pixel.type
  }
}

export const sortCampaigns = (campaignOne, campaignTwo) => {
  let { createdAt: createdOne } = campaignOne;
  let { createdAt: createdTwo } = campaignTwo;

  createdOne = moment(createdOne).format('x');
  createdTwo = moment(createdTwo).format('x');

  if (campaignOne.pin) { createdOne *= 2 }
  if (campaignTwo.pin) { createdTwo *= 2 }

  return createdTwo - createdOne;
}

export const setCampaignUrl = (_campaign, data) => {
  if (!data) { return _campaign }

  const { defaultDomain, domainsDataById, defaultSystemDomain } = data;

  let { customDomain, key = '' } = _campaign;
  if (!customDomain) { customDomain = defaultDomain }

  const dataByDomain = domainsDataById[customDomain];
  if (!dataByDomain) { return false }

  _campaign.dataByDomain = dataByDomain;
  _campaign.fullUrl = dataByDomain ? `${dataByDomain.url}/${key}` : key;

  const protocol = defaultSystemDomain && defaultSystemDomain.url === dataByDomain.url ? 'https://' : 'http://www.';
  _campaign.fullUrlWithProtocol = dataByDomain ? `${protocol}${dataByDomain.url}/${key}` : key;

  return _campaign;
}

export const getCampaignDomain = (campaign, defaultDomain) => {
  return campaign.customDomain ? campaign.customDomain : defaultDomain;
}

export const rotatorTypes = {
  'direct': {
    value: 'direct', icon: 'mdi-folder-outline', text: 'Direct', color: 'light-blue',
    showKeywordsTable: true, showAsinFieldOnCreate: true, showAsinFieldOnEdit: true, requiredAsinOnEdit: true,
    createText: 'Keyword Rotator will redirect on product page rotating keywords by their priorities.',
    linkExample: 'https://www.amazon.com/dp/ASIN?keywords=keyword'
  },
  'search': {
    value: 'search', icon: 'mdi-magnify', text: 'Search', color: 'light-blue',
    showKeywordsTable: true, showAsinFieldOnCreate: true, showAsinFieldOnEdit: true, requiredAsinOnEdit: true,
    createText: 'Keyword Rotator will redirect on Amazon search rotating keywords by their priorities and leaving only your product on the page.',
    linkExample: 'https://www.amazon.com/s?k=keyword&rh=ASIN'
  },
  'cart': {
    value: 'cart', icon: 'mdi-basket-outline', text: 'Cart', color: 'light-blue',
    showKeywordsTable: false, showAsinFieldOnCreate: true, showAsinFieldOnEdit: true, requiredAsinOnEdit: true,
    createText: 'Keyword Rotator will redirect on an Add-to-Cart page for your product.',
    linkExample: 'https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=ASIN&Quantity.1=1'
  },
  'review': {
    value: 'review', icon: 'mdi-message-text-outline', text: 'Review', color: 'light-blue',
    showKeywordsTable: false, showAsinFieldOnCreate: true, showAsinFieldOnEdit: true, requiredAsinOnEdit: true,
    createText: 'Keyword Rotator will redirect on a Create-a-Review page for your product.',
    linkExample: 'https://www.amazon.com/review/create-review?asin=ASIN'
  },
  'directFullParams': {
    value: 'directFullParams', icon: 'mdi-fullscreen-exit', text: 'Direct With Additional Parameters', color: 'light-blue',
    showKeywordsTable: true, showAsinFieldOnCreate: true, showAsinFieldOnEdit: true, requiredAsinOnEdit: true,
    createText: 'Keyword Rotator will redirect on product page with additional parameters rotating keywords by their priorities.',
    linkExample: 'https://www.amazon.com/dp/ASIN1/ref=sr_1_8?qid=1625572509&sr=8-8&keywords=YOUR_KEYWORDS&s=gateway'
  },
  'viaBrand': {
    value: 'viaBrand', icon: 'mdi-filter-outline', text: 'Brand', color: 'light-blue',
    showKeywordsTable: false, showAsinFieldOnCreate: false, showAsinFieldOnEdit: true, requiredAsinOnEdit: false,
    createText: 'Target specific keywords when sending customers to Amazon by restricting products by brand and keyword. Use ASIN and price to narrow down the list if needed.',
    linkExample: 'https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=sugar+scrub&field-brand=FOREST+HEAL&rh=i:aps,ssx:relevance'
  },
  'buyTogether': {
    value: 'buyTogether', icon: 'mdi-cart-outline', text: 'Buy Together', color: 'light-blue',
    showKeywordsTable: false, showAsinFieldOnCreate: false, showAsinFieldOnEdit: false, requiredAsinOnEdit: false,
    createText: 'Send traffic to a cart page that combines 2 or more products.',
    linkExample: 'https://www.amazon.com/gp/aws/cart/add.html?ASIN.1=B00SUZWGHM&Quantity.1=1&ASIN.2=B00809ERAM&Quantity.2=1'
  },
  'canonical': {
    value: 'canonical', icon: 'mdi-toolbox-outline', text: 'Canonical', color: 'light-blue',
    showKeywordsTable: false, showAsinFieldOnCreate: true, showAsinFieldOnEdit: true, requiredAsinOnEdit: true,
    createText: 'Search-engine friendly Amazon URL.',
    linkExample: 'https://www.amazon.com/Samsung-Galaxy-S10-Screen-Protector/dp/B07NQ2MBSW'
  },
  'alexaAmazonChoice': {
    value: 'alexaAmazonChoice', icon: 'mdi-star-outline', text: 'Alexa’s top picks - Amazon’s Choice', color: 'light-blue',
    showKeywordsTable: false, showAsinFieldOnCreate: true, showAsinFieldOnEdit: true, requiredAsinOnEdit: true,
    createText: 'Keyword Rotator will redirect on ‘Alexa’s Top Picks’ for Amazon’s Choice with a specific keyword and ASIN.',
    linkExample: 'https://asmw.amazon.com/search?ref=msv_sr&keyword=garlic+press&asins=B0798KPH5X-C'
  },
  'alexaBestSeller': {
    value: 'alexaBestSeller', icon: 'mdi-license', text: 'Alexa’s top picks - Best Seller', color: 'light-blue',
    showKeywordsTable: false, showAsinFieldOnCreate: true, showAsinFieldOnEdit: true, requiredAsinOnEdit: true,
    createText: 'Keyword Rotator will redirect on ‘Alexa’s Top Picks’ for Best Seller with a specific keyword and ASIN.',
    linkExample: 'https://asmw.amazon.com/search?ref=msv_sr&keyword=garlic+press&asins=B0798KPH5X-B'
  },
  'walmartViaBrand': {
    value: 'walmartViaBrand', icon: 'mdi-tag-text-outline', text: 'Walmart Via Brand', color: 'light-blue',
    showKeywordsTable: true, showAsinFieldOnCreate: false, showAsinFieldOnEdit: false, requiredAsinOnEdit: false,
    createText: 'Target specific keywords when sending customers to Walmart by restricting products by brand and keyword.',
    linkExample: 'https://www.walmart.com/search?cat_id=0&facet=brand%3ALipozene&query=diet+pills'
  },
  'walmartViaSeller': {
    value: 'walmartViaSeller', icon: 'mdi-grid', text: 'Walmart Via Seller', color: 'light-blue',
    showKeywordsTable: true, showAsinFieldOnCreate: false, showAsinFieldOnEdit: false, requiredAsinOnEdit: false,
    createText: 'Target specific keywords when sending customers to Walmart by restricting products by seller and keyword.',
    linkExample: 'https://www.walmart.com/search?cat_id=0&facet=retailer%3APalos+Buddies&query=laundry+sheets'
  },
  'walmartDirect': {
    value: 'walmartDirect', icon: 'mdi-monitor-share', text: 'Walmart Direct', color: 'light-blue',
    showKeywordsTable: false, showAsinFieldOnCreate: false, showAsinFieldOnEdit: false, requiredAsinOnEdit: false,
    createText: 'Keyword Rotator will redirect on walmart product page.', linkExample: 'https://www.walmart.com/ip/itemID'
  },
}