import Vue from 'vue';
import copy from 'copy-to-clipboard';
import {validationMessages} from "../helpers/contants";

export const delay = (timeout) => {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

export const downloadFile = (options) => {
  openLink(options);
}

export const openLink = (options) => {
  const { src, isBlank = false, fileName } = options;

  const link = document.createElement('a');
  link.setAttribute('href', src);

  if (isBlank) {
    link.setAttribute('target', '_blank');
  }

  if (fileName) {
    link.setAttribute('download', fileName);
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const showNotification = (options = {}) => {
  const baseOptions = { ignoreDuplicates: true };
  let { type = 'success', title, group = 'global', text } = options;

  if (!title) { return }

  if (validationMessages[title]) { title = validationMessages[title] }

  const allOptions = { ...baseOptions, group, title, type  };
  if (text) { allOptions.text = text }

  Vue.notify(allOptions);
}

export const validURL = (url) => {
  const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return !!pattern.test(url);
}

export const copyTextToClipboard = (text, isShowNotification = true) => {
  copy(text);

  if (isShowNotification) {
    showNotification({ title: 'Copied!' });
  }
}

export const getDataFromError = (err) => {
  return err?.response?.data || {};
}

export const validPassword = (password) => {
  return (password && (password.length >= 6) && !!password.match(/.*[0-9].*/));
}

export const setErrors = (errors, vueInstance) => {
  Object.keys(errors).forEach(_error => {
    const errorMessage = errors[_error];
    vueInstance.errors[_error] = validationMessages[errorMessage] || errorMessage;
  });
}

export const capitalize = function(string) {
  if (typeof string !== 'string') { return string }
  return string.charAt(0).toUpperCase() + string.slice(1);

}