import Vue from 'vue';
import Vuex from 'vuex';

import rotatorStore from './rotator';
import domainsStore from './domains';
import usersStore from './users';
import qrCodeStore from './qrcode';
import {mapCampaign} from "../js/rotator";
import config from '../../config';
import moment from "moment";
import {DATE_FORMATS} from "../helpers/contants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dataByMarketplaceId: {
      'ATVPDKIKX0DER': {url: 'amazon.com', country: 'USA', abbr: 'US', currency: '$', currencyAbbr: 'USD', marketplaceId: 'ATVPDKIKX0DER'},
      'A1AM78C64UM0Y8': {url: 'amazon.com.mx', country: 'Mexico', abbr: 'MX', currency: '$', currencyAbbr: 'MXN', marketplaceId: 'A1AM78C64UM0Y8'},
      'A2EUQ1WTGCTBG2': {url: 'amazon.ca', country: 'Canada', abbr: 'CA', currency: '$', currencyAbbr: 'CAD', marketplaceId: 'A2EUQ1WTGCTBG2'},
      'A2Q3Y263D00KWC': {url: 'amazon.com.br', country: 'Brazil', abbr: 'BR', currency: '$', currencyAbbr: 'BRL', marketplaceId: 'A2Q3Y263D00KWC'},
      'A2VIGQ35RCS4UG': {url: 'amazon.ae', country: 'U.A.E.', abbr: 'AE', marketplaceId: 'A2VIGQ35RCS4UG'},
      'A1PA6795UKMFR9': {url: 'amazon.de', country: 'Germany', abbr: 'DE', marketplaceId: 'A1PA6795UKMFR9'},
      'ARBP9OOSHTCHU': {url: 'amazon.eg', country: 'Egypt', abbr: 'EG', marketplaceId: 'ARBP9OOSHTCHU'},
      'A1RKKUPIHCS9HS': {url: 'amazon.es', country: 'Spain', abbr: 'ES', marketplaceId: 'A1RKKUPIHCS9HS'},
      'A13V1IB3VIYZZH': {url: 'amazon.fr', country: 'France', abbr: 'FR', marketplaceId: 'A13V1IB3VIYZZH'},
      'A1F83G8C2ARO7P': {url: 'amazon.co.uk', country: 'UK', abbr: 'GB', marketplaceId: 'A1F83G8C2ARO7P'},
      'A21TJRUUN4KGV': {url: 'amazon.in', country: 'India', abbr: 'IN', marketplaceId: 'A21TJRUUN4KGV'},
      'APJ6JRA9NG5V4': {url: 'amazon.it', country: 'Italy', abbr: 'IT', marketplaceId: 'APJ6JRA9NG5V4'},
      'A1805IZSGTT6HS': {url: 'amazon.nl', country: 'Netherlands', abbr: 'NL',  marketplaceId: 'A1805IZSGTT6HS'},
      'A17E79C6D8DWNP': {url: 'amazon.sa', country: 'Saudi Arabia', abbr: 'SA', marketplaceId: 'A17E79C6D8DWNP'},
      'A2NODRKZP88ZB9': {url: 'amazon.se', country: 'Sweden', abbr: 'SE', marketplaceId: 'A2NODRKZP88ZB9'},
      'A33AVAJ2PDY3EV': {url: 'amazon.com.tr', country: 'Turkey', abbr: 'TR', marketplaceId: 'A33AVAJ2PDY3EV'},
      'A19VAU5U5O7RUS': {url: 'amazon.sg', country: 'Singapore', abbr: 'SG', marketplaceId: 'A19VAU5U5O7RUS'},
      'A39IBJ37TRP1C6': {url: 'amazon.com.au', country: 'Australia', abbr: 'AU', marketplaceId: 'A39IBJ37TRP1C6'},
      'A1VC38T7YXB528': {url: 'amazon.co.jp', country: 'Japan', abbr: 'JP', marketplaceId: 'A1VC38T7YXB528'},
      'A1C3SOZRARQ6R3': {url: 'amazon.pl', country: 'Poland', abbr: 'PL',  marketplaceId: 'A1C3SOZRARQ6R3'},
    },
    user: null,
    loading: true,
    initialLoaded: false,
    showMobileMenu: false,
    menu: [
      { id: 'signin', title: 'Sign In', path: '/signin', icon: 'mdi-account-circle-outline', showIfUserLogged: false },
      { id: 'signup', title: 'Sign Up', path: '/signup', icon: 'mdi-login-variant', showIfUserLogged: false },
      { id: 'rotator', title: 'Rotator', path: '/rotator', icon: 'mdi-restore', showIfUserLogged: true },
      { id: 'domains', title: 'Domains', path: '/domains', icon: 'mdi-web', showIfUserLogged: true },
      { id: 'settings', title: 'Settings', path: '/settings', icon: 'mdi-cog-outline', showIfUserLogged: true },
      { id: 'access', title: 'Access', path: '/access', icon: 'mdi-account-cog-outline', showIfUserLogged: true, checkAccess: true },
      { id: 'logout', title: 'Logout', icon: 'mdi-logout-variant', showIfUserLogged: true }
    ]
  },
  mutations: {
    setUser(state, data) {
      if (typeof data !== 'object') { return }

      if (data.accessGrantedUntil) {
        data.accessGrantedUntilFormatted = moment(data.accessGrantedUntil).format(DATE_FORMATS.fullDateWithTime);
      }

      state.user = data;
    },
    setLoading(state, newLoading = false) {
      state.loading = newLoading;
    },
    setInitialDataLoaded(state) {
      state.initialLoaded = true;
    },
    setMobileMenuVisibility(state, show = false) {
      state.showMobileMenu = show;
    },
    logout(state) {
      state.user = null;
      state.loading = false;
      state.initialLoaded = false;

      state.domains.allDomains = [];
      state.domains.loaded = false;

      state.rotator.loaded = false;
      state.rotator.campaigns = [];
      state.rotator.campaignsStatistics = {};
      state.rotator.keywordDataByCampaign = {};
      state.rotator.pixels = [];
      state.rotator.rotatorLoaded = false;

      state.users.userList = [];
      state.users.loaded = false;
    }
  },
  actions: {
  },
  getters: {
    getMenu(state) {
      const { user } = state;

      return state.menu.filter(menuItem => {
        const { showIfUserLogged, checkAccess } = menuItem;

        if (checkAccess && (!user || !user.isAdmin)) {
          return false;
        }

        if (
          (user && showIfUserLogged === true)
          || (!user && showIfUserLogged === false)
        ) { return true }
      });
    },
  },
  modules: {
    rotator: rotatorStore,
    domains: domainsStore,
    users: usersStore,
    qrcode: qrCodeStore
  }
});
