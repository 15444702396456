import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import authApi from '../api/auth';
import rotatorApi from "../api/rotator";
import domainsApi from "../api/domains";

Vue.use(VueRouter);

const routes = [
  {
    path: '/signin',
    name: 'Signin',
    meta: { checkNotLogged: true, hideLoader: true },
    component: () => import(/* webpackChunkName: "signin" */ '../views/Signin.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: { checkNotLogged: true, hideLoader: true },
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue')
  },
  {
    path: '/passwordReset',
    name: 'NewPassword',
    meta: { checkNotLogged: true, hideLoader: true },
    component: () => import(/* webpackChunkName: "recover" */ '../views/NewPassword.vue')
  },
  {
    path: '/recover',
    name: 'RecoverPassword',
    meta: { checkNotLogged: true, hideLoader: true },
    component: () => import(/* webpackChunkName: "recover" */ '../views/RecoverPassword.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { checkLogged: true, hideLoader: true },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/domains',
    name: 'Domains',
    meta: { checkLogged: true },
    component: () => import(/* webpackChunkName: "domains" */ '../views/Domains.vue')
  },
  {
    path: '/access',
    name: 'Users',
    meta: { checkLogged: true },
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/rotator/:id',
    name: 'RotatorEdit',
    meta: { checkLogged: true },
    component: () => import(/* webpackChunkName: "rotatorEdit" */ '../views/RotatorEdit.vue')
  },
  // {
  //   path: '/redirect/:key',
  //   name: 'RotatorRedirect',
  //   meta: { isRedirect: true },
  //   component: () => import(/* webpackChunkName: "rotatorRedirect" */ '../views/RotatorRedirect.vue')
  // },
  {
    path: '/rotator/statistics/:id',
    name: 'RotatorStatistics',
    meta: { checkLogged: true },
    component: () => import(/* webpackChunkName: "rotatorStatistics" */ '../views/RotatorStatistics.vue')
  },
  {
    path: '/rotator',
    name: 'Rotator',
    meta: { checkLogged: true },
    component: () => import(/* webpackChunkName: "rotator" */ '../views/Rotator.vue')
  },
  {
    path: '/',
    redirect: '/signin',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '*',
    redirect: '/',
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  let { user, initialLoaded } = store.state;
  const { path, meta: { checkNotLogged, checkLogged, isRedirect, hideLoader } } = to;

  if (hideLoader) {
    store.commit('setLoading', false);
  }

  if (!initialLoaded && !isRedirect) {
    user = await authApi.getUser();
    store.commit('setInitialDataLoaded');
  }

  const isUserValid = user && user.email;

  if (path === '/access' && isUserValid && !user.isAdmin) {
    return next({ path: '/' });
  }

  if (path.indexOf('/rotator') !== -1 && isUserValid) {
    await domainsApi.getDomains();
    await rotatorApi.getCampaignsStatistics();
    await rotatorApi.getAllCampaigns();
  }

  if (path.indexOf('/domains') !== -1 && isUserValid) {
    await rotatorApi.getAllCampaigns();
  }

  if (checkNotLogged && isUserValid) {
    return next({ path: '/rotator' });
  }

  if (checkLogged && !isUserValid) {
    return next({ path: '/signin' });
  }

  next();
});

export default router;
